VERTICALIQ = {

  common : {
    init : function() {
      this.initJumpLists();
      this.initToolTips();
      this.initSelects();
      this.initModals();
      this.initVideoModals();
      this.initTabs();
      this.initPageFilters();
      this.initSortableTables();
      this.initTruncateExpand();

      if($('.shareable').length) {
        VERTICALIQ.share_snippets.init();
      }
    },
    
    initPageFilters : function() {
      
      // Show/Hide Date Filters
      if($(".date-filter").length) {
        $(".date-range-select").change(function(event) {
          var $datefilter = $(this).parents('.date-filter');
          var $range      = $datefilter.find('.date-range');
          var $lbl        = $datefilter.find('label');

          if($(this).val() == 3) {
            $range.show();
            $lbl.hide();
          } else {
            $range.hide();
            $lbl.show();
          }
        }).change();
        
        // Date Inputs
        // $(".date-range input[type='date']").dateinput({ format: 'yyyy-mm-dd' });
      }
    },
    
    finalize : function() {},

    initJumpLists : function() {
      if($('.jumplist').length > 0) {
        $(".jumplist").change(function() {
          if ($(this).val() != '#') {
            window.location =  $(this).val();
          }
        });
      }
    },

    initToolTips : function() {
      if($('.has-tooltip[title]').length > 0) {
        $('.has-tooltip[title]').tooltipster({
          theme: 'dark',
          contentAsHTML: true,
          maxWidth: 305
        });
      }
    },

    initSelects : function() {
      if($('select.select2').length > 0) {
        $('select.select2').select2({
          width: "100%"
        });
      }
      if($('select.select2-simple').length > 0) {
        $('select.select2-simple').select2({
          minimumResultsForSearch: Infinity,
          width: 'resolve' // need to override the changed default
        });
      }
      // window.onresize = function() {
      //   $('select.select2, select.select2-simple').each(function() {
      //     $(this).trigger("change");
      //   });
      // }
    },

    initModals : function() {
      if(!$('.modal-link').length) return;
      $('.modal-link').modaal();
    },

    initVideoModals : function() {
      if(!$('.video-modal-link').length) return;
      $(".video-modal-link").modaal({
        type: 'video'
      });
    },

    initTabs : function() {
      if($('.tabs').length) {

        $('.tabs').each(function() {
          var $tabs = $(this);

          // Some tabs are css-only, so don't use JS
          if ($tabs.hasClass('no-js')) {
            return false;
          }

          // when clicking back button within a page that uses tabs, switch tabs
          window.addEventListener('popstate', function(e) {
            VERTICALIQ.common.selectTabOnLoad($tabs);
          });

          $tabs.find('li > a').on('click', function(e) {
            e.preventDefault();
            var title = $(this).text();
            var url = $(this).attr('href') + '-ns'; // Add 'ns' for no-scroll on load
            window.history.pushState({}, title, url);
            $(this).tab('show');
          });

          VERTICALIQ.common.selectTabOnLoad($tabs);          
        });
      }

      if($('.select-tab').length) {
        $('.select-tab').each(function() {
          $(this).on('change', function() {
            $(this).find('option:selected').tab('show');
          }).change();
        });
      }
    },

    selectTabOnLoad : function($tabs) {
      var selectFirstTab = true,
          selectedTab = window.location.hash;

      if(selectedTab) {
        // Remove the "-ns" no-scroll suffix
        if (selectedTab.slice(-3) == '-ns') {
          selectedTab = selectedTab.slice(0,-3);
        }
        if(selectedTab != '#ind-results' && $tabs.find("a[href='" + selectedTab + "']").length) {
          selectFirstTab = false;
          $tabs.find("a[href='" + selectedTab + "']").tab('show');
        }
      }
      if(selectFirstTab) {
        $tabs.find('li:first-child > a').click();
      }
    },

    initSortableTables : function() {
      if($('.table-sortable').length) {
        $('.table-sortable').stupidtable();
      }
    },

    initTruncateExpand : function() {
      // Individual Truncate/Expand Links
      const truncExp = document.querySelectorAll('.truncate-expand');
      truncExp.forEach((el) => {
        const splitter = '. ';
        const fullText = el.innerHTML;
        const truncatedText = fullText.split(splitter)[0];

        if (fullText.split(splitter)[1]) {
          // Replace element's HTML with the truncated text
          el.innerHTML = truncatedText + '.';

          // Append a link to expand to the full text
          const moreLink = document.createElement('a');
          moreLink.textContent = "(more...)";
          moreLink.classList.add('truncated', 'truncate-toggle');
          moreLink.addEventListener('click', (e) => {
            e.preventDefault();
            const tgt = e.target;
            if(tgt.classList.contains('truncated')) {
              el.innerHTML = fullText;
              tgt.textContent = "(less...)";
              tgt.classList.remove('truncated');
            } else {
              el.innerHTML = truncatedText + '. ';
              tgt.textContent = "(more...)";
              tgt.classList.add('truncated');
            }
          });
          el.after(moreLink);
        }
      });

      // Expand/Contract All Links
      const expandAll = document.querySelectorAll('.expand-all');
      const collapseAll = document.querySelectorAll('.collapse-all');
      expandAll.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const collapsed = document.querySelectorAll('.truncated');
          collapsed.forEach((f) => {
            f.click();
          });
        });
      });
      collapseAll.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const toggles = document.querySelectorAll('.truncate-toggle');
          toggles.forEach((f) => {
            if (!f.classList.contains('truncated')) {
              f.click();
            }
          });
        });
      });
    }
  },
  
  
  /* Subscriber Home
  ---------------------------------------------------------------- */
  subscriber_home : {
    init : function() {
      
      // Show spinner when searching
      $('#find_industry form').on('submit', VERTICALIQ.subscriber_home.searchSpinner);

      $('#local-select').on('change', function() {
        $('.local-economy-stats').css('opacity', .25)
          .after('<i class="fas fa-3x fa-spinner fa-pulse local-economy-stats-loading"></i>');
        window.location = $(this).val();
      });

      // Toggle Industry Spotlights
      $('.spotlights-toggle').on('click', function(e) {
        e.preventDefault();
        var $this = $(this);
        $this.next('.industry-spotlights').toggleClass('is-visible');
      });

      // Expand all spotlights/niche profiles
      $('.expand-all-niche').on('click', function(e) {
        e.preventDefault();
        $('#ind-categories .industry-spotlights').addClass('is-visible');
      });
      $('.contract-all-niche').on('click', function(e) {
        e.preventDefault();
        $('#ind-categories .industry-spotlights').removeClass('is-visible');
      });
    },

    searchSpinner : function() {
      $("#tab-results").show().find('a').click();
      $('#ind-results .results').hide();
      $('#ind-results .searching').show();
    }
  },
  
  
  /* Industry Dashboard
  ---------------------------------------------------------------- */
  industry_dashboard : {
    init : function() {      
      $("[id*='shape-']").on('mouseover click', function(e) {
        var segment = $(this).data('segment');
        $('.working-capital').find('.current').removeClass('current');
        $(this).addClass('current');
        $('.wcc-content').find('.wcc-' + segment).addClass('current');
        e.preventDefault();
      });
    }
  },
  
  
  /* Call Prep Sheet
  ---------------------------------------------------------------- */
  callprep : {
    init : function() {
      $('#actions .print-me').click(function() {
        window.print();
        return false;
      });
    }
  },
  
  
  /* Profile Pages
  ---------------------------------------------------------------- */
  profile : {
    init : function() {
      if($('section.web-links').length > 0) {
        this.initWebLinks();
      }

      this.initPrintButton();
    },

    setCheckboxParentClass : function($cb) {
     $cb.closest('li').toggleClass('checked', $cb.prop('checked'));
    },

    initWebLinks : function() {
      $('section.web-links .html h2 a').attr('target','_blank');
    },

    initPrintButton : function() {
      $('.print-dropdown-button').tooltipster({
        trigger: 'click',
        theme: 'simple',
        contentAsHTML: true,
        minWidth: 300,
        maxWidth: 320,
        interactive: true
      });
    }
  },

  /* News Dashboard
  ---------------------------------------------------------------- */
  news_dashboard : {
    init : function() {
      // Select first tab by default
      $(".news-categories .toc li:first-child a").tab('show');
    }
  },
  
  /* Resource Center
  ---------------------------------------------------------------- */
  resource_center : {
    init : function() {
      // console.log("resource center init");
      var mixer = mixitup('.resource-grid', {
                    multifilter: {
                      enable: false
                    },
                    callbacks: {
                      onMixStart: function(state, futureState) {
                        // console.log(futureState.activeFilter.selector);
                      }
                    }
                  });
    }
  },

  
  /* Local Economic Profiles
  ---------------------------------------------------------------- */
  local_profiles : {
    stateLinksInit : function() {
      $('.state-link').click(function(e) {
        e.preventDefault();

        var $dest = $('#nav-counties'),
            url = '/local_economic_profiles/county_list',
            state = $(this).text();
        $('.tab-counties').css('display','list-item').find('a').tab('show');

        $.ajax({
          type: "GET",
          url: $(this).attr('href')
          })
          .done(function(data) {
            $dest.html(data);
            $('#state-name').text(state);
          })
          .fail(function() {
            $dest.html('<div class="alert alert-error"><p>Error loading data.</p></div>')
          });
      });
    }
  },

  
  /* Industry Target
  ---------------------------------------------------------------- */
  industry_target : {
    init : function() {

      var thisPage = VERTICALIQ.industry_target;

      $('.report-type').change(function() {
        thisPage.showReportCategory($(this).val());
        thisPage.showReportOptions($(this).val());
        thisPage.showReportFilters($(this).val());
        thisPage.showSubmit();
      });

      $('.report-rank').change(function() {
        thisPage.showReportFilters($('.report-type').val());      
        thisPage.showReportQty();
        thisPage.showSubmit();

      });

      $('.category-name').change(function() {
        $('#filter-category').text($(this).find('option:selected').text());
        thisPage.showSubmit();
      });

      thisPage.showReportCategory( $('.report-type').val());
      thisPage.showReportOptions( $('.report-type').val());
      thisPage.showReportFilters( $('.report-type').val());
      thisPage.showReportQty();
      thisPage.showSubmit();
    },

    showSubmit : function() {
      var reportType = $('#report-form .report-type').val();
      var isValid = true;
      if(reportType == '') {
        isValid = false;
      }
      if(reportType == 'usage' && $('.category-usage .category-name').val() == '') {
        isValid = false;
      }
      if(reportType == 'benchmark' && $('.category-benchmark .category-name').val() == '') {
        isValid = false;
      }
      if(reportType == 'forecast' && $('.category-forecast .category-name').val() == '') {
        isValid = false;
      }
      
      if(isValid) {
        $('#report-form input[type=submit]').removeClass('disabled').prop('disabled',false);
      } else {
        $('#report-form input[type=submit]').addClass('disabled').prop('disabled',true);
      }    
    },

    showReportCategory : function(reportType) {
      $('[class^="category-"]').hide();
      $('.category-' + reportType).show();
    },

    showReportOptions : function(reportType) {
      switch(reportType) {
        case 'usage':
          $('.report-option-filter').show();
          break;

        case 'benchmark':
          $('.report-option-filter').show();
          break;

        case 'forecast':
          $('.report-option-filter').show();
          break;

        case 'failure':
          $('.report-option-filter').hide();
          $('#rank-asc').attr('checked', 'checked').change();
          break;
      }
    },

    showReportQty : function() {
      if($('#filter').is(':checked')) {
        $('.report-qty').hide();
      } else {
        $('.report-qty').show();
      }
    },

    showReportFilters : function(reportType) {
      $('.filter').hide();

      if($('#filter').is(':checked')) {
        $('.filter').show();
      }
    }
  },
  
  /* Local Economic Profiles: Dashboard
  ---------------------------------------------------------------- */
  local_profiles_dashboard : {
    init : function() {
      // Select first tab by default
      $(".local-profiles .toc li:first-child a").tab('show');
    }
  },

  /* SalesKit
  ---------------------------------------------------------------- */
  saleskit : {
    init : function() {
      var saleskit = this;
      this.initAdminForm();

      $('.saleskit-tab').on('click', function(e) {
        e.preventDefault();

        var $this = $(this);
        var pane = $this.find('> a').attr('href');

        if ($('.saleskit-tab.current a').attr('href') == pane) {
          return;
        }

        if($('.saleskit-tab.current').length > 0) {
          saleskit.animateOut($('.saleskit-tab.current'));
        } else {
          $('.saleskit-tab').addClass('is-inactive');
        }
        saleskit.animateIn($this);

        $('.saleskit-tab-spacer:not(.is-active)').addClass('is-active');

        if($('.saleskit-pane.current').length > 0) saleskit.animateOut($('.saleskit-pane.current'));
        saleskit.animateIn($(pane));

        $('.saleskit-content-title').addClass('is-active')
        $('.saleskit-tabs').addClass('is-active')
      });

      if(window.location.hash) {
        var hash = window.location.hash,
            $tab = $('a[href="' + hash + '"]').parent('li');
        $tab.trigger('click');
        $(window).scrollTop(0);
      }
    },

    animateOut : function($el) {
      $el.addClass('is-exiting').removeClass('current');
      window.setTimeout(function() {
        $el.removeClass('is-exiting').addClass('is-inactive');
      }, 400);
    },

    animateIn : function($el) {
      $el.addClass('is-entering').removeClass('is-inactive');
      window.setTimeout(function() {
        $el.removeClass('is-entering').addClass('current');
      }, 400);
    },

    initAdminForm : function() {
      if($('#saleskit_form').length == 0) return;

      // When changing the SalesKit Type, update the display name
      $('#saleskit_override_so_type').change(function() {
        $('#saleskit_override_name').val($(this).find('option:selected').text());
      })
      $('#saleskit_override_name').val($('#saleskit_override_so_type').find('option:selected').text());

      // Reveal the name and industry customization fields
      $('.customize-name').click(function() {
        $('#saleskit_override_name_input').removeClass('hidden');
        $(this).hide();
      });
      $('.customize-industry').click(function() {
        $('#saleskit_override_industry_input').removeClass('hidden');
        $(this).hide();
      });
    }
  },

  saleskit_override : {
    init : function() {
      VERTICALIQ.saleskit.initAdminForm();
    }
  },

  cobranding : {
    init : function() {
      $('.cobranding-modal-link').modaal({
        after_open: function() {
          VERTICALIQ.cobranding.initCookies();
        }
      });
      var urlParams = new URLSearchParams(window.location.search);
      if(urlParams.has('printCustomer')) {
        $('#print-customer-link').click();
      }
    },
    initCookies : function() {
      // Set cookies for cobranding fields
      ['name','jobtitle','email','phone'].forEach(field => {
        let formField = document.querySelector('.cobranded-options .cobranded-' + field);
        let currentVal = getCookie('cobranded-' + field);
        if (currentVal) {
          formField.value = getCookie('cobranded-' + field);
        }
        formField.addEventListener('change', (event) => {
          if (event.target.value) {
            setCookie('cobranded-' + field, event.target.value, 60);
          } else {
            setCookie('cobranded-' + field, '', 60);
          }
        });
      });
    }
  },

  share_snippets : {
    init : function() {
      const share_tooltip = `<div class="share-buttons-container">
                              <p class="share-buttons-description">Share content:</p>
                              <div class="share-buttons-buttons">
                                <button 
                                  id = "email-text-btn"
                                  class="email-share-button"
                                  data-network="email" 
                                  data-url="verticaliq.com"
                                  title="Email Text"
                                  data-email-subject="Check this out from Vertical IQ">
                                    <i class="fa fas fa-envelope"></i>
                                </button>
                                <button
                                  id="copy-text-btn"
                                  title="Copy Text"
                                  class="copy-text-btn">
                                  <i class="fa fas fa-copy"></i>
                                </button>
                              </div>
                            </div>`;
      
      // Show share buttons in tooltip
      $('.shareable').tooltipster({
        theme: 'dark',
        content: share_tooltip,
        contentAsHTML: true,
        trigger: 'click',
        interactive: true,
        onlyOne: true,
        functionReady: function(instance, helper) {
          const shareButtons = document.querySelectorAll(".share-buttons-container button");
          const copyTextButton = document.getElementById("copy-text-btn");
          const emailTextButton = document.getElementById("email-text-btn");
          const selectionText = helper.origin.innerHTML;
          const formattedText = selectionText.charAt(0).toUpperCase() + selectionText.slice(1);
          const alertTextDefault = 'Share content:';
          const alertText = document.querySelector('.share-buttons-description');

          // Update the share buttons title and message based on clicked-on text
          shareButtons.forEach((button) => {
            const shareThisData = button.dataset;
            shareThisData.message = `${formattedText};`

            button.onmouseover = (e) => {
              alertText.textContent = button.getAttribute("title");
            }
            button.onmouseout = (e) => {
              alertText.textContent = alertTextDefault;
            }
          })  
          
          // copy button functionality
          copyTextButton.onclick = (e) => {
            e.stopPropagation()
            if (navigator.clipboard) {
              navigator.clipboard.writeText(`Did you know: ${formattedText}\nFind out more: verticaliq.com/leverage-industry-intelligence`)  
              .then(() => {
                alertText.textContent = "Copied to clipboard!"
                setTimeout(function() {
                  alertText.textContent = alertTextDefault;
                }, 1100)
              })
            } 
          }
          //email button functionality
          emailTextButton.onclick = () => {
            const subject = "Check this out from Vertical IQ";
            const body = `Did you know: ${formattedText}\n\nSource: verticaliq.com`;
            const recipient = '';
            const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
          }
        }
      });

      
    }
  },
  
  /* Admin
  ---------------------------------------------------------------- */
  admin : {
    init : function() {
      // Select first tab by default
      $('#industry-select').on('change', function() {
        if ($(this).val() != '#') {
          window.location =  $(this).val();
        }
      });
    }
  },

}


/* via http://paulirish.com/2009/markup-based-unobtrusive-comprehensive-dom-ready-execution/ */
UTIL = {
 
  fire : function(func,funcname, args){ 
    var namespace = VERTICALIQ;  // indicate your obj literal namespace here 
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] == 'function'){
      namespace[func][funcname](args);
    }  
  }, 
 
  loadEvents : function(){
    var bodyId = document.body.id;
 
    // hit up common first.
    UTIL.fire('common');
 
    // do all the classes too.
    $.each(document.body.className.split(/\s+/),function(i,classnm){
      classnm = classnm.replace('-','_'); // replace dashes in body class name with underscores for js functions
      UTIL.fire(classnm);
      UTIL.fire(classnm,bodyId);
    }); 
    UTIL.fire('common','finalize'); 
  } 
 
};


$.fn.spin = function(opts) {
  this.each(function() {
    var $this = $(this),
        data = $this.data();

    if (data.spinner) {
      data.spinner.stop();
      delete data.spinner;
    }
    if (opts !== false) {
      data.spinner = new Spinner($.extend({color: $this.css('color')}, opts)).spin(this);
    }
  });
  return this;
};
 
// kick it all off here 
$(document).ready(UTIL.loadEvents);



// Utility Cookie Functions
// ----------------------------------------------------------------

function setCookie(cookieName, cookieValue, expDays) {
  let date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = cookieName + "=" + cookieValue + "; " + expires + "; path=/";
}

function getCookie(cookieName) {
  const name = cookieName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded .split('; ');
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  })
  return res;
}