var VERTICALIQ = VERTICALIQ || {};

/* Financial Benchmarks
---------------------------------------------------------------- */
VERTICALIQ.financial_benchmarks = {
  init: function() {
    $('#financial_benchmarks_form').submit(function(e) {
      e.preventDefault();
      VERTICALIQ.financial_benchmarks.loadTables();
    });

    this.loadTables();
  },

  loadTables : function() {
    var dataString = $('#financial_benchmarks_form').serialize(),
        dataUrl = $('#financial_benchmarks_form').attr('action'),
        $dest = $('#financial_benchmarks_tables');

    $dest.html('<div class="loading has-leader has-trailer"><i class="fas fa-2x fa-spinner fa-pulse"></i> Loading</div>');

    $.ajax({
      type: "GET",
      url: dataUrl,
      data: dataString
      })
      .done(function(data) {
        $dest.html(data);
        VERTICALIQ.common.initToolTips();
      })
      .fail(function() {
        $dest.html('<div class="alert alert-error"><p>Error loading data.</p></div>')
      });
  }
}